import Service from "../Service";

//Services
const resource = "monitoringbus/"

export default {
    list(requestID) {  
        return Service.post(resource + "list", "", {
            params: { requestID: requestID },
        });
    },

    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },

    search(dtr, requestID) {
        return Service.post(resource + "search", dtr, {
            params: { requestID: requestID },
        });
    },

    save(dtr, requestID) {
        return Service.post(resource + "save", dtr, {
            params: { requestID: requestID },
        });
    },
}